import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import ContactComponent from "../components/contactComponent"

import "../styles/pages-global.less"
import "../styles/layout.less"
import shoes from "../images/shoe.png"


const Services = () => (
  <>
    <SEO title="Usługi" />
    <Layout>
      <section className="page-container">
        <div className="page-content container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="section-subtitle subpage-subtitle">Usługi szewskie</h2>
              <p>
                Obuwie to jeden z najważniejszych elementów garderoby. Jako
                przedmioty codziennego użytku są narażone na otarcia, uderzenia,
                deszcz, śnieg czy błoto. W konsekwencji mogą ulec uszkodzeniu.
                Nie trzeba jednak ich od razu wyrzucać, tylko przynieść je do
                Nas, a my zajmujemy się naprawą obuwia. zreperujemy każdy rodzaj
                butów – sportowe, codzienne, wizytowe, specjalistyczne – ze
                skóry naturalnej bądź zamszu z dekoracyjnymi elementami. Dzięki
                temu i przy odpowiedniej pielęgnacji, może posłużyć przez wiele
                lat.
              </p>
              <p>
                Zdobyte przez lata doświadczenie pozwoli zająć się nam Twoimi
                butami najlepiej jak potrafimy .
              </p>
            </div>
            <div className="col-lg-6">
              <div className="photo-container shoes">
                <img className="photo-article" src={shoes} alt="szewc" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <h2 className="section-subtitle subpage-subtitle">U nas wykonasz:</h2>
              <ul className="subpage-list">
                <li>fleki, zelówki</li>
                <li>klejenia</li>
                <li>rozciąganie obuwia</li>
                <li>szycie uszkodzeń</li>
                <li>wymiana zamków i suwaków</li>
                <li>wyścielenie wytartych pięt</li>
                <li>dorabianie pasków</li>
                <li>wszywanie nowych gumek, wymiana klamerek</li>
                <li>wszywanie klinów do cholewek</li>
                <li>
                  na życzenie klienta fleki i zelówki możemy wykonać ze skóry
                </li>
              </ul>
            </div>
            <div className="col-lg-6 ">
              <ContactComponent />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  </>
)

export default Services
